html, body{
	min-height: 100%;
}



.tsap{


		
		font-size: 14px;
		word-wrap: break-word;
		margin: 0px;
		padding: 0px;
		font-family: $primary-font;
		font-weight: $primary-font-normal;
		
	
		.animated{
			animation-duration: 0.3s;
		}	
		


		*:focus {
             outline: 0;
           }
	

       
		

   .set-back{
	   	& > img{
	   		display: none;
	   	}
   }


   .container{
   	position: relative;
   }

		
.tooltip{

	position: inherit;
	opacity: 1;
}


	strong{
		font-weight: $primary-font-bold;
		
	}
					


	input[type="text"], input[type="email"], input[type="submit"], input[type="button"], select, textarea{  -webkit-appearance: none;
    -moz-appearance: none;
    appearance: none; @include border-radius (0px);}


    

	
    .full-height{
    	height: 100%;
    }
	
	.table-wrap{

		display: table;
		width: 100%;
		height: 100%;
		vertical-align: bottom;
		margin: 0px;
		table-layout:fixed;
		background: transparent;



		.table-cell{

			display: table-cell;
			
			&.bottom-line{

				vertical-align: bottom;
			}

			&.top-line{

				vertical-align: top;
			}

			&.middle-line{

				vertical-align: middle;
			}

		}
	}



	h1, h2, h3, h4, h5, h6{

		&:first-child{

			margin-top: 0px !important;
		}
		&:last-child{

			margin-bottom: 0px !important;
		}
	}


	p{

		&:last-child{

			margin-bottom: 0px !important;
		}
	}


	

	.my-row{

		width: 100%;
		float: left;
	}



	img{

		max-width: 100%;
		height: auto;

		
	}

	.pull-text-center{

		text-align: center !important;

		& *{
			text-align: center !important;
		}

		

		.site-button{
			display: inline-block;
			float: none;
		}
	}
	.pull-text-left{

		text-align: left !important;

		h1, h2, h3, h4, h5, h6{
			text-align: left !important;
		}
	}
	.pull-text-right{

		text-align: right;
	}
	.pull-text-justify{

		text-align: justify;
	}

	.palce-right-clear{

		float: right;
		clear: both;
	}

	.palce-left-clear{

		float: left;
		clear: both;
	}

	.palce-right{

		float: right;
		
	}

	.palce-left{

		float: left;
		
	}

	.clear{

		clear: both;
	}

	

	a{

		cursor: pointer;
		text-decoration: none;
		@include transition(all 0.3s ease 0s);
		color: $site-black;
		
		

		&:hover{
			
			color: $site-grey;
			
		}

		

		&.site-button{
			color: #fff;
			font-size: 16px;
			text-transform: uppercase;
			padding: 10px 30px;
			line-height: normal;
			float: left;
			text-decoration: none !important;
			margin-top: 5px;
		}


	}

	


}
