/*
 * jQuery FlexSlider v2.0
 * http://www.woothemes.com/flexslider/
 *
 * Copyright 2012 WooThemes
 * Free to use under the GPLv2 license.
 * http://www.gnu.org/licenses/gpl-2.0.html
 *
 * Contributing author: Tyler Smith (@mbmufffin)
 */

 
/* Browser Resets */
.flex-container a:active,
.flexslider a:active,
.flex-container a:focus,
.flexslider a:focus  {outline: none;}
.slides,
.flex-control-nav,
.flex-direction-nav {margin: 0; padding: 0; list-style: none;}

.flexslider li{
	margin: 0px !important;
	padding: 0px !important;

	&:before{
		display: none;
	}
}


/* FlexSlider Necessary Styles
*********************************/ 
.flexslider {margin: 0; padding: 0; width:100%;  float:left;}
.flexslider .slides > li {display: none; -webkit-backface-visibility: hidden; position:relative;} /* Hide the slides before the JS is loaded. Avoids image jumping */
.flexslider .slides img {width: 100%; height: auto;}
.flex-pauseplay span {text-transform: capitalize;}


/* Clearfix for the .slides element */
.slides:after {content: "."; display: block; clear: both; visibility: hidden; line-height: 0; height: 0;} 
html[xmlns] .slides {display: block;} 
* html .slides {height: 1%;}

/* No JavaScript Fallback */
/* If you are not using another script, such as Modernizr, make sure you
 * include js that eliminates this class on page load */
.no-js .slides > li:first-child {display: block;}


/* FlexSlider Default Theme
*********************************/
.flexslider {margin:0px; position: relative;  zoom: 1;}
.flex-viewport {max-height: 2000px; -webkit-transition: all 1s ease; -moz-transition: all 1s ease; transition: all 1s ease; overflow:hidden !important;}
.loading .flex-viewport {max-height: 300px;}
.flexslider .slides {zoom: 1;}

.carousel li {margin-right: 5px}


/* Direction Nav */
.flex-direction-nav{position: absolute; left: 0px; bottom: 100px; width: 100%; list-style: none !important; text-align: center; height: 0px;}

.flex-prv-wrap{
	float: left;
}
.flex-nxt-wrap{
	float: right;
}

.flex-direction-nav-wrapper{
	display: inline-block;
	z-index: 100;
	position: relative;
	height: 0px;
}


.flex-direction-nav{
	width: 50px;
	li{
		float: left;

		&:last-child{
			float: right;
		}
	}
}
.flex-direction-nav a {width: 18px; height: 31px; display: block; font-size: 0px;}

/* .flex-next {background: url(../Images/nxt-slide.png) no-repeat center center / cover !important;}

.flex-prev {background: url(../Images/prv-slide.png) no-repeat center center / cover !important;} */



/*.flexslider:hover .flex-next {opacity: 0.8; right: 5px;}
.flexslider:hover .flex-prev {opacity: 0.8; left: 5px;}
.flexslider:hover .flex-next:hover, .flexslider:hover .flex-prev:hover {opacity: 1;}
.flex-direction-nav .flex-disabled {opacity: .3!important; filter:alpha(opacity=30); cursor: default;}
*/



/* Control Nav */

.flex-control-nav {margin:0 0 14px 0 !important; position: inherit; bottom: inherit; width: 50px; position: relative; height: 20px; font-size: 16px; text-align: center;}

.flex-control-nav li {margin: 0px !important; zoom: 1; display:inline-block; vertical-align: top; color: #fff; line-height: normal; padding: 0px; position: relative;}
.flex-control-nav li:first-child{margin-left:0px;}
.flex-control-paging li a {color: #fff;  display: none; text-decoration: none !important; cursor: normal;}
.flex-control-paging li a.flex-active{
	display: block;
}


.flex-control-thumbs {margin: 5px 0 0; position: static; overflow: hidden;}
.flex-control-thumbs li {width: 25%; float: left; margin: 0;}
.flex-control-thumbs img {width: 100%; display: block; opacity: .7; cursor: pointer;}
.flex-control-thumbs img:hover {opacity: 1;}
.flex-control-thumbs .flex-active {opacity: 1; cursor: default;}

@media screen and (max-width: 860px) {
  .flex-direction-nav .flex-prev {opacity: 1; left: 0;}
  .flex-direction-nav .flex-next {opacity: 1; right: 0;}
}