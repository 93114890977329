.tsap{
	
	footer{
		padding: 80px 0 50px 0;
		position: relative;
	}

	.hmbg-tag{
		position: absolute;
		right: -13px;
		bottom: 50px;
		font-size: 14px;
		color: #999999;
		transform: rotate(-90deg);
		font-family: $secondary-font;
		font-weight: $secondary-font-normal;
		font-size: 10px;
	}

	.footer-logo{
		width: 165px / 940px * 100%;
		margin-right: 40px;
		float: left;
	}

	.footer-content{
		width: 620px / 940px * 100%;
		float: left;
		margin-top: 18px;

		.row{
			margin-left: -10px;
			margin-right: -10px;
		}

		.my-col{
			padding-left: 10px;
			padding-right: 10px;
		}

		.element-row{
			margin-top: 25px;

			&:first-child{
				margin-top: 0px;
			}
		}
	}
	
	
}