// fonts

$primary-font: 'Karla', sans-serif;
$primary-font-normal: 400;
$primary-font-bold: 700;

$secondary-font: 'Open Sans', sans-serif;
$secondary-font-normal: 400;









// colors

$site-black: #000;
$site-grey: #808080;
$site-grey-light: #999999; 

