.tsap{


	.entry{

		.site-link{
			border-bottom: 2px solid;
			padding-bottom: 5px;
			text-decoration: none;

			&:hover{
				border-width: 6px;
				color: #000;
			}
		}

		em{
			font-style: normal;
			color: $site-grey;
		}
		
		h5{
			border: none;
			padding: 20px 0;
			margin: 60px 0 35px;
			font-size: 30px;
			line-height: 39px;
			font-weight: $primary-font-bold;
			color: $site-black;
			position: relative;

			&:last-child{
				margin-bottom: 0px;
				padding-bottom: 0px;
			}
			&:first-child{
				margin-top: 0px;
			}

			span{
				position: absolute;
				left: 0px;
				top: 0px;
				width: 106px;
				height: 2px;
				background-color: $site-black;
			}

			&:before{
				content: '"';
			}
			&:after{
				content: '"';
			}
		}

		p{
			font-size: 14px;
			line-height: 22px;
			color: $site-black;
			margin-bottom: 23px;
		}

		ul, ol{
			margin-bottom: 23px;
			margin-top: 0px;

			&:last-child{
				margin-bottom: 0px;
			}
		}

		li{
			
			margin-bottom: 5px;
			margin-top: 0px;
			font-size: 14px;
			line-height: 22px;
			color: $site-black;

			&:last-child{
				margin-bottom: 0px;
			}

			
		}

		h2{

			font-size: 35px;
			line-height: 39px;
			font-weight: $primary-font-bold;
			color: $site-black;
			margin-bottom: 25px;
			margin-top: 35px;
		}

		h3{
			font-size: 20px;
			line-height: 20px;
			font-weight: $primary-font-bold;
			color: $site-black;
		}

		h4{
			font-size: 16px;
			line-height: 20px;
			font-weight: $primary-font-bold;
			color: $site-black;
		}
	}


	.section-row{
		&:first-child{
			margin-top: 90px;
		}

		&.section-row-type-one{
			padding: 45px 0;
		}
		&.section-row-type-two{
			padding: 45px 0 60px 0;
		}
	}

	.centerd-wrap-one{
		width: 790px / 940px * 100%;
		margin: 0 auto;
		padding-right: 42px;
	}

	.element-row{
		margin-top: 60px;

		&:first-child{
			margin-top: 0px;

			.content-gallery{
				margin-top: 0px;
			}
		}

		&:last-child{
			.content-gallery{
				margin-bottom: 0px;
			}

			
		}
	}

	.half-col{
		.entry{
			padding-right: 116px;
		}
		&:nth-child(2){
			margin-top: 10px;
		}
	}

	.half-col-row{
		margin-bottom: 108px;

		&:last-child{
			margin-bottom: 0px;
		}
	}

	


	/* full slider start */
	.full-slider-wrap{
		
		position: relative;
		

		&.inner-slider{
			margin: 0 auto;

			.full-slider-bar{
				width: 320px;
			}
		}

		&.team-large-image{
			height: 520px !important;

			.slider-content{
				display: none;
			}

			
		}

		.full-slider-bar{
			position: absolute;
			width: 746px / 1280px * 100%;
			height: 28px;
			background: #000;
			z-index: 2;
			bottom: -14px;
			

			&.Fullslider-left-bar{
				left: 0px;
			}
			&.Fullslider-right-bar{
				right: 0px;
			}
		}

		
		.slider-content{
			height: 100%;
			text-align: center;
			width: 100%;
			position: absolute;
			left: 0px;
			top: 0px;
			z-index: 1;

			.slider-entry{
				display: inline-block;

				&:hover{
					h1 span:before{
						height: 6px;
						position: relative;
						top: 4px;
					}
				}

				span{
					display: block;
				}
			}

			& *{

				color: #fff;
			}

			h1{
				font-weight: $primary-font-bold;
				font-size: 45px;
				line-height: normal;
				position: relative;
				margin: 0;
				padding: 0 0 20px 0;

				span{

					position: absolute;
					width: 100%;
					left: 0px;
					bottom: 0px;

					&:before{

						width: 106px;
						height: 2px;
						background-color: #fff;
						display: inline-block;
						content: ' ';
						position: relative;
					}
				}
			}

			h3{
				font-weight: $primary-font-bold;
				font-size: 16px;
				line-height: 19px;
				margin-top: 5px;
			}
		}
	



		.flex-direction-nav{
			width: 100%;
			position: absolute;
			bottom: inherit;
			left: 0px;
			top: 50%;
			padding: 0 25px;
			margin-top: -21px;
			z-index: 3;

			li{
				float: right;

				a{
					width: 12px;
					height: 21px;
					display: block;
					background-repeat: no-repeat;
					background-position: center center;
					background-size: cover;

					&.flex-prev{
						background-image: url('../Images/large-slider-prv.png');
					}

					&.flex-next{
						background-image: url('../Images/large-slider-nxt.png');
					}
				}

				&:first-child{
					float: left;
				}
			}
		}

		.flexslider, .slides, .slides li, .flex-viewport{
			height: 100%;
		}

		.slides li{
			&.set-back{

				background-position: center center;
				background-repeat: no-repeat;
				background-size: cover;

				
			}
		}
	}
	/* full slider end */

	/* box content */


		.box-content-row{
			margin-left: -20px;
			margin-right: -20px;
			margin-top: 95px;

			&:first-child{
				margin-top: 0px;
			}

			&:last-child{
				.my-col{
					&:nth-child(4){
						.boxcontent-info{
							height: auto;
							top: inherit;
							margin-top: 28px;
						}
					}
				}
			}


			.my-col{
					padding: 0 20px;

					

					&:nth-child(2){
						margin-top: 140px;
					}
					&:nth-child(3){
						margin-top: 14px;
						clear: both;
					}

					&:nth-child(4){
						clear: both;
						float: right;
					}
			}
		}

		

		.boxcontent-main{

			
			text-align: center;

			&.box-content-leftbar{
				
				.boxcontent-img{
					padding-left: 30px;
				}
				

				.boxcontent-black-bar{
					left: 0px;
				}
			}
			&.box-content-rightbar{

				.boxcontent-img{
					padding-right: 30px;
				}
				

				.boxcontent-black-bar{
					right: 0px;
				}
			}
		}

		.boxcontent-img{
			
			display: block;
			position: relative;

		.boxcontent-black-bar{
			width: 150px;
			height: 28px;
			bottom: -14px;
			background-color: $site-black;
			position: absolute;
			z-index: 1;
		}	

			img{
				width: 100%;
				height: auto;
				@include transition(all 0.3s ease 0s);
			}

			&:hover{
				img{
					opacity: 0.5;
				}
			}
		}
		.boxcontent-info{
			display: block;
			height: 0px;
			position: relative;
			top: 28px;

			h2, h3{

				color: $site-black;
				font-weight: $primary-font-bold;
				
			}

			h2{
				font-size: 20px;
				line-height: 12px;
				margin: 0 0 17px 0;
				padding: 0px;
			}

			h3{
				font-size: 14px;
				line-height: 6px;
				margin: 0px;
				padding: 0px;
			}
		}
	

	/* box content */

	
	/* info table */
	.project-info-table{
		td{
			min-width: 90px;
			padding: 4px 4px 4px 0;
			vertical-align: top;
		}
	}
	/* info table */


	/* content gallery */
	.content-gallery{

		margin-top: 10px;
		margin-bottom: 30px;


		.flex-direction-nav{
			width: 100%;
			position: absolute;
			bottom: inherit;
			left: 0px;
			top: 50%;
			padding: 0px;
			margin-top: -41px;
			z-index: 3;

			li{
				float: right;

				a{
					width: 12px;
					height: 21px;
					display: block;
					background-repeat: no-repeat;
					background-position: center center;
					background-size: cover;

					&.flex-prev{
						background-image: url('../Images/content-gal-prv.png');
					}

					&.flex-next{
						background-image: url('../Images/content-gal-nxt.png');
					}
				}

				&:first-child{
					float: left;
				}
			}
		}





		.content-gal-img{
			width: 798px / 940px * 100%;
			height: 500px;
			margin: 0 auto;

			&.set-back{
				background-size: cover;
				background-position: center top;
				background-repeat: no-repeat;
			}
		}

		.content-gal-info{
			text-align: center;
			color: $site-grey-light;
			margin-top: 20px;
		}
	}
	/* content gallery */


	/* map */
	.map-wrap{
		
		position: relative;
		

		.main-map{
			height: 450px;

			&.set-back{
				background-size: cover;
				background-repeat: no-repeat;
				background-position: center top;
			}
		}

		&.map-left-bar{
			padding-left: 30px;

			.map-black-bar{
				left: 0px;
			}
		}

		&.map-right-bar{
			padding-right: 30px;

			.map-black-bar{
				right: 0px;
			}
		}

		.map-black-bar{
			position: absolute;
			width: 150px;
			height: 28px;
			bottom: -14px;
			background-color: $site-black;
		}
	}
	/* map */







}