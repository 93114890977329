.tsap{

   header{
   	position: fixed;
   	z-index: 200;
   	width: 100%;
   	left: 0px;
   	top: 20px;
		
		.container{
			width: 1000px;
			padding: 0px;
		}

		.logo{
			float: left;
			box-shadow: 4px 4px 8px rgba(0, 0, 0, 0.2);
		}


		nav{

			height: 70px;
			float: right;
			background-color: #fff;
			color: #000;
			padding: 0 19px;
			box-shadow: 4px 4px 8px rgba(0, 0, 0, 0.2);

			& > ul{
				height: 100%;
				& > li{

					height: 100%;
					font-weight: $primary-font-bold;
					line-height: 70px;
					margin: 0;
					padding: 0 26px;
					position: relative;

					& > a{
						position: relative;

						&:before{
							position: absolute;
							left: 0px;
							bottom: 16px;
							content: ' ';
							width: 100%;
							height: 2px;
							background-color: $site-black;
							display: block;
							visibility: hidden;
						}
					}

					&:hover{
						
						& > a{
							color: $site-black;
							&:before{
								visibility: visible;
							}
						}

						& > ul{
							display: block;
						}
					}

					&.active{
						
						& > a{
							&:before{
								visibility: visible;
							}
						}
					}

					& > ul{
						position: absolute;
						background-color: #fff;
						left: -19px;
						right: -19px;
						padding: 0 0 8px 0;
						margin: 0px;
						display: none;

						& > li{
							width: 100%;
							margin: 0px;
							padding: 0px;
							text-align: center;
							line-height: normal;
							font-weight: $primary-font-normal;

							a{
								height: auto;
								padding: 8px;
							}
						}
					}

					

					a{
						display: block;
						height: 100%;
						color: $site-black;


					}
				}
			}

			ul{
				list-style: none;
				margin: 0px;
				padding: 0px;

				li{
					float: left;
					color: $site-black;
					font-size: 15px;

				}
			}
		}

   } 
    
}