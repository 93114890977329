@media screen and (min-width: $screen-md + 1px) {

		/* header */
		header{
			.responsive-navigation{
				display: none !important;
			}
		}
		/* header */

		/* full slider start */
		.full-slider-wrap{
			height: 730px !important;
		}
		/* full slider end */

}





/* tab */

@media screen and (max-width: $screen-md) {
	.tsap{
			
			.container{
				width: 600px;
				padding-left: 10px;
				padding-right: 10px;
			}

			.half-col-row{
				margin-bottom: 88px;
			}
			.half-col{
					.entry{
						padding-right: 0px;

						h2{
							font-size: 30px;
						}
					}
				} 

			.row{
				margin-left: -10px;
				margin-right: -10px;
			}

			.my-col{

				padding-left: 10px;
				padding-right: 10px;
			}

			.section-row{
				&.section-row-type-one{
					padding: 10px 0;
				}

				&.section-row-type-two{
					padding: 45px 0 40px 0;
				}
			}

			.centerd-wrap-one{
				padding-right: 0px;
				width: 100%;
			}

			/* header */
			header{
				.container{
					width: 622px;
				}

				.responsive-navigation{
					position: fixed;
					right: 0px;
					top: 0px;
					z-index: 900;
					width: 325px;
					height: 100%;
					background-color: #fff;
					padding: 90px 20px;
					box-shadow: 0 0 5px 2px #666;
					overflow-y: auto;
					display: none;

					.respon-menu-close{
						width: 20px;
						height: 20px;
						position: absolute;
						right: 30px;
						top: 25px;
						background: url('../Images/respon-menu-close.png') no-repeat center center;
						cursor: pointer;
					}

					ul{
						list-style: none;
						margin: 0px;
						padding: 0px;

						li{
							margin: 5px 0 0 0;
							padding: 0px;

							&.sub-open{
								& > a{
									&:after{
										transform: rotate(180deg);
									}
								}
							}

							&:first-child{
								margin-top: 0px;
							}

							a{
								display: block;
								height: 45px;
								line-height: 45px;
								font-size: 15px;
								font-weight: $primary-font-bold;
								color: #fff;
								padding: 0 20px;
								background-color: $site-black;
								position: relative;

								&:after{
									position: absolute;
									right: 20px;
									top: 0px;
									height: 100%;
									content: ' ';
									width: 19px;
									background: url('../Images/respon-arrow.png') no-repeat center center;
									@include transition(all 0.3s ease 0s);
								}
							}

							ul{
								
								display: none;

								li{
									margin-top: 2px;

									&:first-child{
										margin-top: 5px !important;
									}

									a{
										background-color: #fff;
										color: $site-black;
										font-weight: $primary-font-normal;
									}
								}
							}
						}
					}
				}

				.responsive-nav-click{
					width: 70px;
					height: 70px;
					background: url('../Images/responclick-bar.png') no-repeat center center #fff;
					float: right;
					cursor: pointer;
					box-shadow: 4px 4px 8px rgba(0, 0, 0, 0.2);
				}
			}
			/* header */


			/* full slider start */
			.full-slider-wrap{

				&.inner-slider{
					height: 412px !important;
				}

				.full-slider-bar{
					width: 476px / 720px * 100%;
				}
			}
			/* full slider end */


			/* box content */
			.box-content-row{
				margin-right: -10px;
				margin-left: -10px;
				margin-top: 78px;

				.my-col{
					padding: 0 10px;

					&:nth-child(2){
						margin-top: 90px;
					}
					&:nth-child(3){
						margin-top: 40px;
					}
				}
			}

			.boxcontent-info{
				top: 38px;
			}
			/* box content */


			/* content gal */
			.content-gallery{
				margin-bottom: 10px;
				.content-gal-img{
					width: 487px / 580px * 100%;
					height: 305px;
				}
			} 
			/* content gal */



			/* footer */
			.hmbg-tag{
				bottom: 80px;
			}
			footer{
				padding: 50px 0;
				.container{
					padding: 0px;
					width: 480px;
				}

				.footer-logo{
					margin-right: 0px;
					width: 165px;
				}

				.footer-content{
					width: 278px;
					float: right;

					.my-col{
						margin-bottom: 18px;

						&:last-child{
							margin-bottom: 0px;
						}
					}
				}
			}
			/* footer */






	}
}

/* tab */


/* mobile */
@media screen and (max-width: $screen-sm - 1px) {

	.tsap{


		.half-col{
			&:nth-child(2){
				margin-top: 50px;
			}
		}
		
		.container{
			width: 100% !important;
			padding-left: 20px !important;
			padding-right: 20px !important;
		}

			.row{
				margin-left: -20px !important;
				margin-right: -20px !important;
			}

			.my-col{

				padding-left: 20px !important;
				padding-right: 20px !important;
			}


		.section-row{
			&.section-row-type-two{
				padding-top: 25px;
			}
		}

		.element-row{
			margin-top: 35px;
		}

		.half-col-row{
			margin-bottom: 68px;
		}	


		/* header */
		header{
			top: 12px;

			.logo{
				img{
					height: 54px;
					width: auto;
				}
			}

			.responsive-nav-click{
				width: 54px;
				height: 54px;
			}

			.responsive-navigation{
				width: 100%;
			}
		}
		/* header */

		/* full slider start */
		.full-slider-wrap{

			&.inner-slider{
				height: 280px !important;

				.full-slider-bar{
					width: 230px;
				}

				.slider-content{
					h1{
						margin-top: 50px !important;
					}
				}
			}

			.full-slider-bar{
				width: 230px;
			}

			.flex-direction-nav{
				display: none;
			}

			.slider-content{
				

				h1{
					font-size: 35px;
					line-height: 28px;
				}


			}
		} 
		/* full slider end */

		/* box content */
		.box-content-row{
			margin-top: 45px;
			.my-col{
				margin-top: 45px !important;

				&:first-child{
					margin-top: 0px !important;
				}
			}
		}
		.boxcontent-info{
			height: auto;
			position: initial;
			margin-top: 38px;
		}
		/* box content */


		/* content gallery */
		.content-gallery{

			margin-top: 10px;

			.flex-direction-nav{
				display: none;
			}

			.content-gal-img{
				width: 100%;
				height: 178px;
			}
		}
		/* content gallery */

		/* footer */
		footer{
			padding: 40px 0;
			.footer-logo{
				width: auto;
			}
			.footer-content{
				width: auto;
				float: left;
				margin-top: 28px;

				.element-row{
					margin-top: 30px;
				}
			}
		} 
		/* footer */

	}

}
/* mobile */


